import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { graphql, Link } from "gatsby"
import React from "react"

import BlogRoll from "../components/blogRoll"
import ComboCourseList from "../components/comboCourseList"
import CourseConversionV2Card from "../components/courseConversionV2Card"
import CourseSimpleCard from "../components/courseSimpleCard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { conversionV2Courses, workshops } from "../data/day-courses"
import HomeHero from "../images/svg/studying.svg"

const useStyles = makeStyles(() =>
  createStyles({
    font: {
      maxWidth: "463px",
    },
    hero: {
      position: "absolute",
      right: 0,
      top: 0,
      width: "100%",
      maxWidth: "745px",
      zIndex: -1,
    },
    heroTablet: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "100%",
      maxWidth: "745px",
      zIndex: -1,
    },
    heroMobile: {
      width: "100%",
      marginTop: "16px",
    },
    heroText: {
      height: "100%",
      maxHeight: "500px",
      alignItems: "center",
      display: "flex",
    },
    heroTextMobile: {
      paddingTop: "40px",
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
)

interface Props {
  title: string
  heading: string
  subheading: string
}

export const IndexPageTemplate = (props: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Container>
      <SEO
        title="Home"
        description="The Institute of Beauty offers a wide range of ITEC and CIBTAC professional qualifications in the areas of Beauty Therapy, Massage and Complementary Therapies."
      />
      <Grid
        container
        style={{
          height: isDesktop ? "calc(100vh - 64px)" : "100%",
          maxHeight: isDesktop ? "650px" : "none",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          className={isDesktop ? classes.heroText : classes.heroTextMobile}
        >
          <div>
            <h1 className={classes.font}>Your pathway to a new career</h1>
            <p className={classes.font}>
              Now Enrolling for courses starting in September 2022
            </p>
            <Button
              variant="outlined"
              color="primary"
              style={{ textTransform: "none", backgroundColor: "white" }}
              component={Link}
              to="#september-courses"
            >
              September Courses
            </Button>
            {/* <Button
              variant="outlined"
              color="primary"
              style={{
                textTransform: "none",
                backgroundColor: "white",
                marginLeft: "12px",
              }}
              component={Link}
              to="#workshops"
            >
              Workshops
            </Button> */}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={isDesktop ? undefined : classes.heroMobile}
        >
          <HomeHero />
        </Grid>
      </Grid>
      <section
        id="september-courses"
        style={{ paddingTop: "64px", marginTop: "-64px" }}
      >
        <Grid
          container
          style={{ marginTop: isDesktop ? "0" : "80px", marginBottom: "60px" }}
          spacing={4}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <h1>September 2022 Courses</h1>
            <h3>
              Want to "Upskill" or "Add New Qualifications" or add "CPD Hours"
            </h3>
          </Grid>
          <ComboCourseList />
          <BlogRoll />

          {/* {jan2022Courses.map((course, idx) => (
            <Grid key={`course_${idx}`} item xs={12} sm={4}>
              <CourseCard {...course} />
            </Grid>
          ))} */}
          {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: 60 }}>
            <h2>Course Bundles</h2>
          </Grid>
          {courseBundles.map((course, idx) => (
            <Grid key={`day_${idx}`} item xs={12} sm={4}>
              <CourseBundleCard {...course} />
            </Grid>
          ))} */}
          {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: 60 }}>
            <h2>3 Day Courses</h2>
            <h4>Available throughout the year Sept 2021 - May 2022</h4>
          </Grid>
          {threeDayCourses.map((course, idx) => (
            <Grid key={`day_${idx}`} item xs={12} sm={4}>
              <CourseSimpleCard {...course} />
            </Grid>
          ))} */}
          {/* <section id="workshops"></section>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: 60 }}>
            <h1>Workshops</h1>
            <h4>Please note: all course's require payment on booking</h4>
          </Grid>
          {[...workshops].map((course, idx) => (
            <Grid key={`day_${idx}`} item xs={12} sm={4}>
              <CourseSimpleCard {...course} />
            </Grid>
          ))} */}
        </Grid>
      </section>
    </Container>
  )
}

interface IndexPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        [key: string]: any
      }
    }
  }
}

const IndexPage = ({ data }: IndexPageProps) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
